import React, { useRef, useStatem, useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Clipboard } from 'react-native';
import { commonStyle } from '../components/style';
import { getWebsiteInfo, getAgentcenter, manageinviteAndManageicode,getrebateinfo } from '../api/apis';
import SpliteLine from '../components/spliteLine'
import { AntDesign, FontAwesome, Ionicons, Octicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { transactionType, formatTimestampToDateTime } from '../components/util'

const App = () => {

    const [invitationCode, setInvitationCode] = useState('');
    const [addTime, setAddTime] = useState('');
    const [numberRegPers, setNumberRegPers] = useState('');
    const [defaultDomain, setDefaultDomain] = useState('');

    useEffect(() => {

        getWebsiteInfo().then(response => {
            if (response.status == 1) {
                setDefaultDomain(response.Data.default_domain)
            }
        }).catch(error => {
            console.error(error);
        });

        manageinviteAndManageicode().then(response => {
            if (response.status == 1) {
                setNumberRegPers(response.Data1.item[0].number_reg_pers)
                setAddTime(formatTimestampToDateTime(response.Data1.item[0].add_time))
                setInvitationCode(response.Data1.item[0].invitation_code)
            }
        });

    }, []);

    // 复制
    const handleCopyInvitationCode = () => {
        Clipboard.setString(invitationCode);
    }

    const handleCopyRegisterUrl = () => {
        Clipboard.setString(defaultDomain + '?screen=register&invitation_code=' + invitationCode);
    }

    // const rebateinfo = () =>{
    //     getrebateinfo().then(response => {
    //         console.log(response)
    //         if (response.status == 0) {
                
    //         }
    //     });
    // }

    return (
        <View style={styles.container}>

            <View style={{ backgroundColor: '#222430',marginHorizontal:10,borderRadius:10 }}>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 20, alignItems: 'center' }}>

                    <View>
                        <Text style={{ color: '#fff' }}>邀请码：{invitationCode}</Text>
                        <Text style={{ color: '#fff' }}>{addTime}</Text>
                    </View>


                    <Text style={{ color: '#fff' }}>注册({numberRegPers})</Text>
                </View>


                <View style={{ borderTopWidth: 0.2, borderTopColor: 'gray', flexDirection: 'row', justifyContent: 'space-between', height: 50, alignItems: 'center' }}>

                    {/* <TouchableOpacity onPress={rebateinfo} style={{ flex: 1, textAlign: 'center' }}>
                        <Text style={{ color: '#fff', textAlign: 'center' }}>查看返点佣金</Text>
                    </TouchableOpacity> */}

                    <TouchableOpacity onPress={handleCopyInvitationCode} style={{ borderRightWidth: 0.2, borderColor: 'gray', flex: 1, height: 50, justifyContent: 'center' }}>
                        <Text style={{ color: '#fff', textAlign: 'center' }}>复制邀请码</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={handleCopyRegisterUrl} style={{ flex: 1, textAlign: 'center' }}>
                        <Text style={{ color: '#fff', textAlign: 'center' }}>复制注册链接</Text>
                    </TouchableOpacity>
                </View>
            </View>

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        flexDirection: 'colum',
        paddingHorizontal: 5
    },

});

export default App;