import React, { useRef, useStatem, useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { commonStyle } from '../components/style';
import { getWebsiteInfo, getAgentcenter } from '../api/apis';
import SpliteLine from '../components/spliteLine'
import { AntDesign, FontAwesome, Ionicons,Octicons,MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import {unitconversion } from '../components/util'

const App = () => {

    const navigation = useNavigation();


    const [mun, setMun] = useState(0);
    const [todayRebate, setTodayRebate] = useState(0);
    const [edayRebateTotal, setEdayRebateTotal] = useState(0);

    useEffect(() => {

        // getWebsiteInfo().then(response => {
        //     if (response.status == 1) {

        //     }
        // }).catch(error => {
        //     console.error(error);
        // });

        getAgentcenter().then(response => {
            if (response.status == 1) {
                setTodayRebate(response.Data1.item.TodayRebate.Total)
                setEdayRebateTotal(response.Data1.item.edayRebate.Total)
                setMun(response.Data1.item.mun)
            }
           
        }).catch(error => {

            console.error(error);
        });

    }, []);


    return (
        <View style={styles.container}>
            <View style={styles.cardContainer}>

                <View style={styles.cardItem}>

                    <View style={styles.cardItemContent}>
                        <Text style={styles.cardItemLabel}>我的下级(人)</Text>
                        <Text style={styles.cardItemValue}>{mun}</Text>
                    </View>


                    <TouchableOpacity>
                        <View style={[styles.cardItemTop, styles.commissionDetailButton]}>
                            <Text style={styles.cardItemLabel}>返佣明细</Text>
                        </View>
                    </TouchableOpacity>
                </View>

                <SpliteLine />

                <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 10 }}>
                    <View style={styles.cardItem2}>
                        <Text style={styles.cardItemLabel}>昨日佣金</Text>
                        <Text style={styles.cardItemLabel}>{unitconversion(edayRebateTotal)}</Text>
                    </View>
                    <View style={styles.cardItem2}>
                        <Text style={styles.cardItemLabel}>今日佣金</Text>
                        <Text style={styles.cardItemLabel}>{unitconversion(todayRebate)}</Text>
                    </View>

                </View>


            </View>



            <View style={ styles.cardContainerBottom }>

                <View >

                    <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 10 }}>

                        <View style={styles.cardItem2}>

                            <AntDesign name="book" size={30} color={commonStyle.green} />
                            <Text style={styles.cardItemLabel}>代理说明</Text>
                        </View>

                        <TouchableOpacity style={styles.cardItem2}  onPress={()=>{ navigation.navigate('ManageIcode');}}>
                            <AntDesign name="adduser" size={30} color={commonStyle.green} />
                            <Text style={styles.cardItemLabel}>下级开户</Text>
                        </TouchableOpacity>


                    </View>

                </View>
                <SpliteLine />
                <View >

                    <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 10 }}>
                    
                        <TouchableOpacity style={styles.cardItem2}  onPress={()=>{ navigation.navigate('AgentReport');}}>
                            <Octicons name="report" size={30} color={commonStyle.green} />
                            <Text style={styles.cardItemLabel}>代理报告</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.cardItem2}  onPress={()=>{ navigation.navigate('AgentMember');}}>
                            <FontAwesome name="users" size={30} color={commonStyle.green} />
                            <Text style={styles.cardItemLabel}>下级管理</Text>
                        </TouchableOpacity>

                    </View>

                </View>
                <SpliteLine />
                <View >

                    <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 10 }}>

                        <View style={styles.cardItem2}>
                            <MaterialCommunityIcons name="card-account-details-outline" size={30} color={commonStyle.green} />
                            <Text style={styles.cardItemLabel}>下级投注详细信息</Text>
                        </View>

                        <View style={styles.cardItem2}>
                            <MaterialCommunityIcons name="account-details-outline" size={30} color={commonStyle.green} />
                            <Text style={styles.cardItemLabel}>下级交易明细</Text>
                        </View>


                    </View>

                </View>


            </View>



        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        flexDirection: 'colum',
        paddingHorizontal: 5
    },
    cardContainer: {
        backgroundColor: '#222430',
        marginHorizontal: 10,
        borderRadius: 5,
        paddingVertical: 10,
        marginBottom: 10,
    },
    cardContainerBottom:{
        flexDirection: 'column', 
        backgroundColor: '#222430', 
        marginTop: 10, 
        marginHorizontal: 15,
         borderRadius: 5, 
         paddingVertical: 10
    },
    cardItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    cardItem2:{
        justifyContent: 'center', 
        alignItems: 'center', 
        flex: 1
    },
    cardItemContent: {
        flexDirection: 'column',
        marginLeft: 20,
        alignItems: 'center',
    },
    cardItemLabel: {
        color: '#fff',
    },
    cardItemValue: {
        color: '#fff',
        fontSize: 20,
    },
    commissionDetailButton: {
        backgroundColor: commonStyle.green,
        padding: 8,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
});

export default App;