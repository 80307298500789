import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Image, FlatList } from 'react-native';
import { useNavigation,useFocusEffect} from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { getUserbalance } from '../api/apis';
import { unitconversion ,navigateRequireLogin} from '../components/util'
import AsyncStorage from '@react-native-community/async-storage';
import SpliteLine from '../components/spliteLine'

const Wallet = () => {
    const navigation = useNavigation();
    const { i18n, t } = useTranslation();

    const [coinList, setCoinList] = useState([
        { name: 'BTC', num: 0 },
        { name: 'ETH', num: 0 },
        { name: 'TRX', num: 0 },
        { name: 'BNB', num: 0 },
        { name: 'USDC', num: 0 }
    ]);

    const [eye, setEye] = useState('on');
    // const [searchText, setSearchText] = useState('');
    // const [filteredData, setFilteredData] = useState(data);
    const [balance, setBalance] = useState('');
    const [currency, setCurrency] = useState('');

    useEffect(() => {
        // userbalance()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            userbalance()
        }, [])
    );

    const handleEye = (index) => {
        console.log(index);
        if (index === 'on') {
            setEye('off');
        } else {
            setEye('on');
        }
    };

    // const handleSearch = (text) => {
    //     console.log(text)
    //     setSearchText(text);

    //     // 过滤数据
    //     const filtered = data.filter((item) =>
    //         item.name.toLowerCase().includes(text.toLowerCase())
    //     );
    //     setFilteredData(filtered);
    // };


    // 获取余额
    const userbalance = async () => {

        const userinfo = await AsyncStorage.getItem('userinfo');
        if (!userinfo) {
            navigation.navigate('Login');
        }


        getUserbalance().then(response => {
            if (response.status == 1 && response.Data.Code == '1') {
                setBalance(response.Data.BackData);
            }
        }).catch(error => {
            // console.error(error);
        });

        const l = await AsyncStorage.getItem('websiteInfo');
        const lj = JSON.parse(l);
        if (lj.Data.curr != '') {
            setCurrency(lj.Data.curr)
        }


        getUserbalance({ type: "all" }).then(res => {
            const coin = res.Data.other
            const upateCoinList = coinList.map((data) => {
                let coinNum = 0;
                if (data.name === "BTC") {
                    coinNum = coin.BTC/100
                }
                if (data.name === "ETH") {
                    coinNum = coin.ETH/100
                }
                if (data.name === "TRX") {
                    coinNum = coin.TRX/100
                }
                if (data.name === "BNB") {
                    coinNum = coin.BNB/100
                }
                if (data.name === "USDC") {
                    coinNum = coin.USDC/100
                }
              
                return {
                    ...data,
                    num: parseFloat(coinNum),
                };

            });

            setCoinList(upateCoinList)
        })



    };

    const goToRechargeRecordScreen = () => {
        navigation.navigate('RechargeRecord');
    };

    const goToWithdrawalRecordScreen = () => {
        navigation.navigate('WithdrawalRecords');
    };

    const goToContractRecordsScreen = () => {
        navigation.navigate('BillingRecord');
    }

    const goToBettingScreen = () => {
        // navigation.navigate('ContractRecords');
        navigation.navigate('BetRecord');
    }

    const goToExchangeScreen = (amount, currency) => {
        const route = {
            name: 'Exchange', // 路由地址
            params: { amount, currency: currency },
        };
        navigateRequireLogin(navigation, route);
    }

    return (
        <View style={styles.container}>
            <View style={styles.contentContainer}>
                <View style={styles.accountInfoContainer}>
                    <View style={styles.accountInfoHeader}>
                        <Text style={styles.totalAssetsText}>{t('totalAssets')}</Text>
                        {/* <View style={styles.eyeIconContainer}>
                            {eye === 'on' && (
                                <TouchableOpacity onPress={() => handleEye('on')}>
                                    <Feather name="eye" size={20} color="#707070" />
                                </TouchableOpacity>
                            )}
                            {eye === 'off' && (
                                <TouchableOpacity onPress={() => handleEye('off')}>
                                    <Feather name="eye-off" size={20} color="#707070" />
                                </TouchableOpacity>
                            )}
                        </View> */}
                    </View>
                    <View>
                        <Text style={styles.usdtAmountText}>
                            {eye === 'on' ? unitconversion(balance,'',true) : '***** '}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.cnyAmountText}>
                            {currency}
                            {/* = {eye === 'on' ? '9.999999 CNY' : '***** CNY'} */}
                        </Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={styles.iconText}>
                        <TouchableOpacity style={styles.icon} onPress={goToBettingScreen}>
                            <Image style={styles.image} source={require('../../assets/imgs/wallet/bill.png')} />
                        </TouchableOpacity>
                        <Text style={styles.labelTop}>{t('betting')}</Text>
                    </View>

                    <View style={styles.iconText}>
                        <TouchableOpacity style={styles.icon} onPress={goToContractRecordsScreen}>
                            <Image style={styles.image} source={require('../../assets/imgs/wallet/bill.png')} />
                        </TouchableOpacity>
                        <Text style={styles.labelTop}>{t('bill')}</Text>
                    </View>

                    <View style={styles.iconText}>
                        <TouchableOpacity style={styles.icon} onPress={() => goToRechargeRecordScreen()}>
                            <Image style={styles.image} source={require('../../assets/imgs/wallet/deposit.png')} />
                        </TouchableOpacity>
                        <Text style={styles.labelTop}>{t('recharge')}</Text>
                    </View>
                    <View style={styles.iconText}>
                        <TouchableOpacity style={styles.icon} onPress={() => goToWithdrawalRecordScreen()}>
                            <Image style={styles.image} source={require('../../assets/imgs/wallet/extractCurrency.png')} />
                        </TouchableOpacity>
                        <Text style={styles.labelTop}>{t('withdrawal')}</Text>
                    </View>
                </View>

                {/* <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: '#2C3445', width: 150, marginBottom: 10 }}>
                    <MaterialIcons name="search" size={20} color="#fff" />
                    <TextInput
                        style={styles.input}
                        placeholder={t('searchCurrency')}
                        onChangeText={handleSearch}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        maxLength={10}
                        value={searchText}
                    />
                </View> */}

                <View style={styles.separator}></View>

                {/* <View style={{ backgroundColor: '#1B1D29', paddingBottom: 10 }}>
                    <View style={{ flexDirection: 'column' }}>
                        {coinList.map((item, index) => (
                            <TouchableOpacity key={index} onPress={() => goToExchangeScreen(0, item.name)}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        {item.name == 'BTC' &&
                                            <Image style={styles.image} source={require('../../assets/imgs/currency/BTC.png')} />
                                        }
                                        {item.name == 'ETH' &&
                                            <Image style={styles.image} source={require('../../assets/imgs/currency/ETH.png')} />
                                        }
                                        {item.name == 'TRX' &&
                                            <Image style={styles.image} source={require('../../assets/imgs/currency/TRX.png')} />
                                        }
                                        {item.name == 'BNB' &&
                                            <Image style={styles.image} source={require('../../assets/imgs/currency/BNB.png')} />
                                        }
                                        {item.name == 'USDC' &&
                                            <Image style={styles.image} source={require('../../assets/imgs/currency/USDC.png')} />
                                        }
                                        <Text style={styles.walletItemTitle}>{item.name}</Text>
                                    </View>
                                    <View >
                                        <Text style={styles.walletItemTitle}>{item.num}</Text>
                                    </View>
                                </View>
                                <SpliteLine />
                            </TouchableOpacity>
                        ))}
                    </View>
                </View> */}

            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
    },
    contentContainer: {
        width: '95%',
        marginTop: 15
    },
    accountInfoContainer: {
        backgroundColor: '#2C3445',
        borderRadius: 5,
        flexDirection: 'column',
        padding: 10,
    },
    accountInfoHeader: {
        flexDirection: 'row',
    },
    totalAssetsText: {
        color: '#fff',
    },
    eyeIconContainer: {
        marginLeft: 5,
    },
    usdtAmountText: {
        color: '#13B777',
        fontSize: 25,
    },
    cnyAmountText: {
        color: '#13B777',
    },
    section: {
        marginVertical: 20,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    iconText: {
        flexDirection: 'colum',
        alignItems: 'center',
        color: '#828B9F',
    },
    icon: {},
    labelTop: {
        color: commonStyle.textWhiteColor,
        marginTop: 2,
    },
    image: {
        width: 20,
        height: 20,
        marginVertical: 5,
        marginHorizontal: 5,
        borderRadius:90
    },
    separator: {
        height: 1,
        backgroundColor: '#fff',
    },
    input: {
        borderWidth: 0,
        borderBottomColor: 'transparent',
        color: '#fff',
        borderRadius: 2,
        padding: 5,
        width: 130,
    },
    walletItemTitle: {
        fontSize: 16,
        color: '#fff',
        marginTop: 4,
        marginRight: 5
    },
    walletItemText: {
        color: '#fff',
        fontSize: 16,
    }
});

export default Wallet;