import React, { useState, useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, RefreshControl,TextInput, Clipboard } from 'react-native';
import ActiveTabItem from '../components/activeTabItem'
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
import { FontAwesome,MaterialIcons } from '@expo/vector-icons';
import { getTraderecord, selcelagentmember } from '../api/apis';
import { transactionType, formatTimestampToDateTime, vipGrade, unitconversion } from '../components/util'

const App = () => {

    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState(2); // 2注册时间  1:等级 排序
    const [emptyShow, setEmptyShow] = useState(false);
    const [user, setUser] = useState('');
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        handleRefresh()
    }, []);


    useEffect(() => {

        record()

    }, [page, sort, user]);

    // 刷新
    const handleRefresh = () => {
        setRefreshing(true)
        setPage(1)
    };

    // 加载更多数据
    const handleLoadMore = () => {
        setLoadingMore(true);
        setPage(parseInt(page) + 1)
    };



    //获取记录
    const record = async () => {
        // console.log('page:'+ page)
        // console.log('sort:'+ sort)
        // console.log('user:'+ user)

        await selcelagentmember(page, sort, user).then(response => {
            setRefreshing(false);
            setLoadingMore(false);

            // console.log(response.Data1)
            if (response.status == 1) {
                const updateData = response.Data.map(data => {
                    return { ...data, };
                });
                const dataList = data.concat(updateData)
                if (page > 1) {
                    setData(dataList)
                } else {
                    setData(updateData)
                    if (dataList.length == 0) {
                        setEmptyShow(true)
                    }
                }
            }
        }).catch(error => {
            setRefreshing(false);
            setLoadingMore(false);
            setData([])
            // console.error(error);
        });
    };

    const handleCopy = (text) => {
        Clipboard.setString(text);
    }

    const handleSubordinate = (text) => {
        setUser(text)
        setPage(1)
        // handleRefresh()
    }

    const handleSearch = (text) => {
        setSearchText(text)
    };


    const handleSearchButton = () => {
        setUser(searchText)
        if(searchText == ''){
            setPage(1)
            // handleRefresh()
        }

       
    }
    

    const renderItem = ({ item }) => (
        <View style={styles.itemContainer}>
            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGreen}>{item.user_account_number}</Text></View>
                <View><Text style={styles.itemTextGray}>{vipGrade(item.user_integral).inte} </Text></View>
            </View>


            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGray}>下级人数：{item.user_Subordinate_num}  </Text></View>
            </View>
            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGray}>累计返佣：{unitconversion(item.user_total_rebate)}</Text></View>
            </View>
            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGray}>当前余额：{unitconversion(item.user_balance)}</Text></View>
            </View>
            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGray}>注册时间：{formatTimestampToDateTime(item.user_registration_time)}</Text></View>
            </View>
            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGray}>上次登录：{item.user_last_login_time > 0 && formatTimestampToDateTime(item.user_last_login_time)}</Text></View>
            </View>
            <View style={[styles.itemRowContainer, { marginTop: 5 }]}>
                {/* <TouchableOpacity>
                    <Text style={{ color: '#fff' }}>查看返点佣金</Text>
                </TouchableOpacity> */}
                <TouchableOpacity onPress={() => handleSubordinate(item.user_account_number)}>
                    {Number(item.user_Subordinate_num) > 0 && <Text style={{ color: '#fff' }}>查看下级</Text>}
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleCopy(item.user_account_number)}>
                    <Text style={{ color: '#fff' }}>复制账号</Text>
                </TouchableOpacity>
            </View>


        </View>
    );

    return (
        <View style={styles.container}>

            {/* <Image style={styles.image} source={require('../../assets/imgs/common/empty.png')} /> */}

            <View style={{flexDirection:'row',marginHorizontal:10}}>
                <View style={{flex:1, flexDirection: 'row', alignItems: 'center', backgroundColor: '#2C3445',  marginBottom: 20, borderTopLeftRadius:5,borderBottomLeftRadius:5 }}>
                    <MaterialIcons name="search" size={20} color="#fff" />
                    <TextInput
                        style={styles.input}
                        placeholder={''}
                        onChangeText={handleSearch}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        value={searchText}
                    />
                </View>
                <TouchableOpacity onPress={handleSearchButton} style={{backgroundColor:commonStyle.green,height:38,borderTopRightRadius:8,borderBottomRightRadius:8}}>
                    <Text style={{color:'#fff',padding:8}}>搜索</Text>
                </TouchableOpacity>
            </View>
                


            <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10 }}>
                <View>
                    {user == "" && <Text style={{ color: '#fff', fontSize: 16 }}> 我的下级</Text>}
                    {user != "" && <Text style={{ color: '#fff', fontSize: 16 }}> {user}的下级</Text>}
                </View>

                <View style={{ flexDirection: 'row', }}>
                    <TouchableOpacity onPress={() => { setSort(2); handleRefresh() }}>
                        <Text style={[{ color: commonStyle.white, fontSize: 16 }, sort == 2 && { color: commonStyle.green }]}>注册时间↓</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { setSort(1); handleRefresh() }}>
                        <Text style={[{ marginLeft: 10, color: commonStyle.white, fontSize: 16 }, sort == 1 && { color: commonStyle.green }]}>等级↓</Text>
                    </TouchableOpacity>
                </View>

            </View>
            <FlatList
                data={data}
                renderItem={renderItem}
                keyExtractor={(item, index) => index}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                onEndReached={!loadingMore && handleLoadMore}
                onEndReachedThreshold={0.1}
                ListFooterComponent={loadingMore && <ActivityIndicator color={commonStyle.green} />}
            />

            {emptyShow && <View style={{ justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <Text style={{ color: '#fff' }}>{t('noData')}</Text>
            </View>}

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor
    },
    tabContainer: {
        flexDirection: 'row',
        paddingVertical: 10,
        backgroundColor: '#1B1D29',
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 10,
    },
    tabText: {
        color: commonStyle.textGreenColor,
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemContainer: {
        flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#1B1D29',
        marginTop: 5,
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    activeTabItem: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    input: {
        borderWidth: 0,
        borderBottomColor: 'transparent',
        color: '#fff',
        borderRadius: 2,
        padding: 5,
        width: '90%',
    },

});

export default App;