import React, { useRef, useStatem, useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Clipboard,ActivityIndicator } from 'react-native';
import { commonStyle } from '../components/style';
import { getWebsiteInfo, getAgentreport, getAgentcenter, manageinviteAndManageicode, getrebateinfo } from '../api/apis';
import SpliteLine from '../components/spliteLine'
import { AntDesign, FontAwesome, Ionicons, Octicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { transactionType, formatTimestampToDateTime,unitconversion } from '../components/util'

const App = () => {

    const [day, setDay] = useState(0);
    const [resData, setResData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        // getWebsiteInfo().then(response => {
        //     if (response.status == 1) {

        //     }
        // }).catch(error => {
        //     console.error(error);
        // });

        agentreport(day);

    }, []);

    const agentreport = (val) => {
        setLoading(true)
        setDay(val)
        getAgentreport(val).then(response => {
            setLoading(false)
            if (response.status == 1) {
                setResData(response.Data.data)
                
            }
        }).catch(error => {
            setLoading(false)
            console.error(error);
        });

    }

    const buttons = [
        { text: '今日', day: 0 },
        { text: '昨天', day: 1 },
        { text: '七天', day: 2 },
        { text: '本月', day: 3 },
        { text: '上月', day: 4 },
    ];

    const CustomButton = ({ onPress, isActive, text }) => (
        <TouchableOpacity onPress={onPress}>
            <Text style={[{ color: '#fff' }, isActive && { color: commonStyle.green }]}>{text}</Text>
        </TouchableOpacity>
    );

    return (
        <View style={styles.container}>

            <View style={{ marginHorizontal: 10 }}>

                <View style={{ flexDirection: 'row', justifyContent: 'space-around', backgroundColor: '#222430', padding: 10, borderRadius: 5 }}>
                    {buttons.map((button) => (
                        <CustomButton
                            key={button.day}
                            onPress={() => agentreport(button.day)}
                            isActive={day === button.day}
                            text={button.text}
                        />
                    ))}
                </View>

                <View style={{ backgroundColor: '#222430', padding: 10, borderRadius: 5, marginTop: 5 }}>
                    <Text style={{ color: '#fff' }}>报表 团队</Text>
                    <Text style={{ color: '#fff' }}>下级人数是 {resData?.Number_of_subordinates} (人)</Text>
                </View>

                <View style={{ backgroundColor: '#222430', padding: 10, borderRadius: 5, marginTop: 5, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                        <Text style={{ color: '#fff' }}>投注金额</Text>
                        <Text style={{ color: commonStyle.red, textAlign: 'center' }}>{resData?.plus?.Bet_amount && unitconversion(resData?.plus?.Bet_amount)}</Text>
                    </View>
                    <View>
                        <Text style={{ color: '#fff' }}>中奖金额</Text>
                        <Text style={{ color: commonStyle.red, textAlign: 'center' }}>{(resData?.plus?.Winning_amount && unitconversion(resData?.plus?.Winning_amount))}</Text>
                    </View>
                    <View>
                        <Text style={{ color: '#fff' }}>投注人数</Text>
                        <Text style={{ color: '#fff', textAlign: 'center' }}>{resData?.Number_of_bets}</Text>
                    </View>

                </View>

                <View style={{ backgroundColor: '#222430', borderRadius: 5, marginTop: 5, padding: 10 }}>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', }}>
                        <View style={{ backgroundColor: '#287dff', padding: 20, borderRadius: 99, width: 100, height: 100, justifyContent: 'center' }}>
                            <Text style={{ color: '#fff', textAlign: 'center' }}>团队返佣</Text>
                            <Text style={{ color: '#fff', textAlign: 'center' }}>{resData?.plus?.Team_rebate && unitconversion(resData?.plus?.Team_rebate)}</Text>
                        </View>
                        <View style={{ backgroundColor: '#fb5136', padding: 20, borderRadius: 99, width: 100, height: 100, justifyContent: 'center' }}>
                            <Text style={{ color: '#fff', textAlign: 'center' }}>团队盈亏</Text>
                            <Text style={{ color: '#fff', textAlign: 'center' }}>{resData?.plus?.Team_profit_loss && unitconversion(resData?.plus?.Team_profit_loss)}</Text>
                        </View>
                        <View style={{ backgroundColor: '#fb9311', padding: 20, borderRadius: 99, width: 100, height: 100, justifyContent: 'center' }}>
                            <Text style={{ color: '#fff', textAlign: 'center' }}>返点佣金</Text>
                            <Text style={{ color: '#fff', textAlign: 'center' }}>{resData?.TodayRebate?.Total >= 0 && unitconversion(resData?.TodayRebate?.Total)}</Text>
                        </View>
                    </View>
                    <View style={{ alignItems: 'center', marginTop: 10 }}>
                        <Text style={{ color: '#fff' }}>计算团队损益的公式：</Text>
                        <Text style={{ color: '#fff' }}>中奖 - 投注 + 礼金活动 + 团队返佣</Text>
                    </View>

                </View>

                <View style={{ backgroundColor: '#222430', padding: 10, borderRadius: 5, marginTop: 5, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                        <Text style={{ color: '#fff' }}>充值金额</Text>
                        <Text style={{ color: commonStyle.red, textAlign: 'center' }}>{resData?.plus?.Recharge_amount && unitconversion(resData?.plus?.Recharge_amount)}</Text>
                    </View>
                    <View>
                        <Text style={{ color: '#fff' }}>提款金额</Text>
                        <Text style={{ color: commonStyle.red, textAlign: 'center' }}>{resData?.plus?.Withdrawal_Amount && unitconversion(resData?.plus?.Withdrawal_Amount)}</Text>
                    </View>
                    <View>
                        <Text style={{ color: '#fff' }}>团队余额</Text>
                        <Text style={{ color: commonStyle.red, textAlign: 'center' }}>{resData?.Team_balance && unitconversion(resData?.Team_balance)}</Text>
                    </View>

                </View>

                <View style={{ backgroundColor: '#222430', padding: 10, borderRadius: 5, marginTop: 5, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                        <Text style={{ color: '#fff' }}>礼金活动</Text>
                        <Text style={{ color: commonStyle.red, textAlign: 'center' }}>{resData?.plus?.Activity_gift && unitconversion(resData?.plus?.Activity_gift)}</Text>
                    </View>
                    <View>
                        <Text style={{ color: '#fff' }}>首次充值(人)</Text>
                        <Text style={{ color: '#fff', textAlign: 'center' }}>{resData?.Firstrecharge}</Text>
                    </View>
                    <View>
                        <Text style={{ color: '#fff' }}>注册人数</Text>
                        <Text style={{ color: '#fff', textAlign: 'center' }}>{resData?.Registeredtoday}</Text>
                    </View>

                </View>

            </View>

            {loading && <ActivityIndicator color={commonStyle.green} />}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        flexDirection: 'colum',
        paddingHorizontal: 5
    },

});

export default App;