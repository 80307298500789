import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform, Image, Button, Alert, Linking } from 'react-native';
import { commonStyle } from '../components/style'
import { getRechargeTron, getWebsiteInfo } from '../api/apis';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { unitconversion } from '../components/util'
import config from '../../config';

const RechargeChannel = (props) => {

  const [RechargeWayBank, setRechargeWayBank] = useState([]);
  const [RechargeWayUnion, setRechargeWayUnion] = useState([]);
  const [rechargeWaySort, setRechargeWaySort] = useState([]);
  const [thirdpartyPay, setThirdpartyPay] = useState([]);
  const [bankTitle, setBankTitle] = useState('');
  const [curr, setCurr] = useState('');
  const [kefu, setKefu] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState([]);


  const { t } = useTranslation();
  const navigation = useNavigation();

  useEffect(() => {
    init()
  }, []);


  const init = async () => {
    const response = await getWebsiteInfo();
    if (response.status == '1') {
      setKefu(response.Data.sl)
      const curr = response.Data.curr
      setCurr(curr)

      getRechargeTron(curr).then(response => {
        if (response.status == 1) {
            // console.log(response.item.RechargeWayBank)

          const itemRechargeWaySort = response.item.RechargeWaySort.filter((item) => {
            // return item.types_of == 'bank' || curr == item.curr;
            return  curr == item.curr || 'USDT' == item.curr;
            return item.types_of == 'bank' || curr == item.curr || 'USDT' == item.curr;
            // return item.types_of == 'bank' || item.types_of == 'thirdparty_pay' || curr == item.curr || 'USDT' == item.curr;
          });


          setRechargeWaySort(itemRechargeWaySort)

          // setRechargeWaySort(response.item.RechargeWaySort)

          setPaymentMethod(response.item)

          if(response?.item?.RechargeWayBank){
            setRechargeWayBank(response.item.RechargeWayBank)
          }
        
          setRechargeWayUnion(response.item.RechargeWayUnion)
          setThirdpartyPay(response.item.thirdparty_pay)

          if (response.item.RechargeWayBank) {
            let title = ''
            for (var i = 0; i < response.item.RechargeWayBank.length; i++) {
              if (response.item.RechargeWayBank[i].types_of == 'bank') {
                title = response.item.RechargeWayBank[i].bank
                break
              }
            }

            setBankTitle(title)
          }



        }
      }).catch(error => {
        // console.error(error);
      });

    }
  }

  const handleCustomer = async () => {
    const response = await getWebsiteInfo();
    if (response.status == '1') {
        // response.Data.sl = 'https://t.me/example_use'
        var regex = /^https?:\/\/t\.me\/[a-zA-Z0-9_]+$/;
        if(regex.test(response.Data.sl)){
            if (Platform.OS == 'web') {
                window.location.href = response.Data.sl
            }else{
                Linking.openURL(response.Data.sl)
            }
        }else{
            navigation.navigate('Customer');
        }
    }
    // navigation.navigate('Customer');
  };

  const handleRecharge = (item) => {

    if (item.payType == 'RechargeWayBank') {

      // let title = bankTitle
      // if (bankTitle == 'USDT') {
      //   title = 'USDT'
      // } else {
      //   title = t('bankCard')
      // }

      navigation.navigate('Deposit', { title: item.title,itemKey:item.itemKey,repayment:props.route?.params?.repayment });
      return
    }

    // console.log(item.payType)
    // console.log(thirdpartyPay)
    // console.log(RechargeWayUnion)

    if (item.payType == 'RechargeWayUnion') {
      let rechargeWayUnionData
      for (var i = 0; i < RechargeWayUnion.length; i++) {
        if (RechargeWayUnion[i].title == item.title) {
          rechargeWayUnionData = RechargeWayUnion[i]
          break
        }
      }

      navigation.navigate('RechargeWayUnion', { rechargeWayUnionData: rechargeWayUnionData });
      return
    }

    if (item.payType == 'thirdparty_pay') {

      let thirdpartyPayData
      for (var i = 0; i < thirdpartyPay.length; i++) {
        if (thirdpartyPay[i].title == item.title) {
          thirdpartyPayData = thirdpartyPay[i]
          break
        }
      }

      thirdpartyPayData.logo_url = item?.logo_url

      navigation.navigate('ThirdpartyPay', { thirdpartyPayData: thirdpartyPayData });
      return
    }

    // console.log(item.payType)
    // const type = item.payType
    // console.log(paymentMethod[type])

    navigation.navigate('Payment', { payItem: paymentMethod[item.payType][0],curr:item.curr,payType:item.payType });

  }

  return (
    <View style={styles.container}>
      <View >

        {RechargeWayBank.map((item, index) => (
        <React.Fragment key={index}>
          <TouchableOpacity onPress={() => handleRecharge({payType:'RechargeWayBank',itemKey:index,title:item.bank})} style={{
            backgroundColor: '#222430',
            marginBottom: 10,
            borderRadius: 5,
            paddingHorizontal: 10,
            paddingVertical: 15,
          }}>
            <Text style={styles.text}>{item.bank ? item.bank : bankTitle} {RechargeWayBank.length > 1 }</Text>
            <Text style={[styles.text, { color: 'gray' }]}>{t('low')}{item.price_min}, {t('high')}{item.price_max}</Text>
          </TouchableOpacity>
        </React.Fragment>
        ))}


        {rechargeWaySort.map((item, index) => (
          <React.Fragment key={index}>
            <TouchableOpacity onPress={() => handleRecharge(item)} style={{
              backgroundColor: '#222430',
              marginBottom: 10,
              borderRadius: 5,
              paddingHorizontal: 10,
              paddingVertical: 15,
            }}>
              <Text style={styles.text}>{item.title ? item.title : bankTitle}</Text>
              <Text style={[styles.text, { color: 'gray' }]}>{t('low')}{item.price_min}, {t('high')}{item.price_max}</Text>
            </TouchableOpacity>
          </React.Fragment>
        ))}

        {/* {kefu && Platform.OS == 'web1' &&
          <a href={kefu} target='_blank'  style={{
            backgroundColor: '#222430',
            marginBottom: 10,
            borderRadius: 5,
            paddingHorizontal: 10,
            paddingVertical: 12,
            height: 60,
            justifyContent: 'center',
            textDecoration: 'none'
          }}><div>
            <Text style={[styles.text,{lineHeight: 55,marginLeft: 8}]}>{t('bankCard')}</Text>
          </div>
          </a>
        }
 */}

          {/* vn708 不显示这个 */}

          {/* {!config.channelPayBankHide && 
            <TouchableOpacity onPress={() => handleCustomer()} style={{
              backgroundColor: '#222430',
              marginBottom: 10,
              borderRadius: 5,
              paddingHorizontal: 10,
              paddingVertical: 12,
              height: 60,
              justifyContent: 'center',
            }}>
              <Text style={styles.text}>{t('bankCard')}</Text>
            </TouchableOpacity>
          } */}
        

      </View>

    </View>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: commonStyle.bgColor,
    paddingHorizontal: 15
    // height: '99%'
  },
  text: {
    color: '#fff'
  }
})
export default RechargeChannel;